var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"bg"}),_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_vm._m(2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showModal),expression:"!showModal"}],staticClass:"btn-face"},[_c('div',[_vm._v("我已知晓，开始设置")]),_c('input',{ref:"fileInput",attrs:{"id":"upload","type":"file","accept":"image/*","capture":"camera"},on:{"change":_vm.handleImg}})]),(_vm.showModal)?_c('div',{staticClass:"crop-modal"},[_c('img',{key:_vm.imageSrc,ref:"image",attrs:{"src":_vm.imageSrc,"alt":"Preview"}}),_c('div',{staticClass:"crop-buttons"},[_c('div',{staticClass:"btn-clap",on:{"click":function($event){return _vm.rotateImage(-90)}}},[_vm._m(3),_c('div',{staticClass:"btn-text"},[_vm._v("左旋转")])]),_c('div',{staticClass:"btn-clap",on:{"click":function($event){return _vm.rotateImage(90)}}},[_vm._m(4),_c('div',{staticClass:"btn-text"},[_vm._v("右旋转")])]),_c('div',{staticClass:"btn-clap",on:{"click":function($event){return _vm.zoomImage(0.1)}}},[_vm._m(5),_c('div',{staticClass:"btn-text"},[_vm._v("放大")])]),_c('div',{staticClass:"btn-clap",on:{"click":function($event){return _vm.zoomImage(-0.1)}}},[_vm._m(6),_c('div',{staticClass:"btn-text"},[_vm._v("缩小")])])]),_c('div',{staticClass:"bottom-btns"},[_c('div',{staticClass:"btn cancel",on:{"click":_vm.cancelCrop}},[_vm._v("取消")]),_c('div',{staticClass:"btn confirm",on:{"click":_vm.cropImage}},[_vm._v("确认裁剪")])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{staticClass:"guide-img",attrs:{"src":require("../../assets/user_profile_guide2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" 请按下述要求调整上传的照片 "),_c('div',{staticClass:"remark"},[_vm._v("请确保脸部完整截取，其他背景占比越少越好")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/profile_guide_img.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box-btn"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/profile_left.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box-btn"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/profile_right.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box-btn"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/profile_max.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box-btn"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/profile_min.png"),"alt":""}})])
}]

export { render, staticRenderFns }