<template>
  <div class="container">
    <div class="bg" />
    <div class="img-box">
      <img src="../../assets/user_profile_guide2.png" alt="" class="guide-img">
    </div>
    <div class="content">
      <div class="title">
        请按下述要求调整上传的照片
        <div class="remark">请确保脸部完整截取，其他背景占比越少越好</div>
      </div>
      <div class="profile">
        <img class="img" src="../../assets/profile_guide_img.png">
      </div>
      <div v-show="!showModal" class="btn-face">
        <div>我已知晓，开始设置</div>
        <input id="upload" ref="fileInput" type="file" accept="image/*" capture="camera" @change="handleImg">
      </div>
      <!-- 裁剪模态框 -->
      <div v-if="showModal" class="crop-modal">
        <img ref="image" :key="imageSrc" :src="imageSrc" alt="Preview">
        <div class="crop-buttons">
          <div class="btn-clap" @click="rotateImage(-90)">
            <div class="img-box-btn">
              <img src="../../assets/profile_left.png" alt="" class="img">
            </div>
            <div class="btn-text">左旋转</div>
          </div>
          <div class="btn-clap" @click="rotateImage(90)">
            <div class="img-box-btn">
              <img src="../../assets/profile_right.png" alt="" class="img">
            </div>
            <div class="btn-text">右旋转</div>
          </div>
          <div class="btn-clap" @click="zoomImage(0.1)">
            <div class="img-box-btn">
              <img src="../../assets/profile_max.png" alt="" class="img">
            </div>
            <div class="btn-text">放大</div>
          </div>
          <div class="btn-clap" @click="zoomImage(-0.1)">
            <div class="img-box-btn">
              <img src="../../assets/profile_min.png" alt="" class="img">
            </div>
            <div class="btn-text">缩小</div>
          </div>
        </div>
        <div class="bottom-btns">
          <div class="btn cancel" @click="cancelCrop">取消</div>
          <div class="btn confirm" @click="cropImage">确认裁剪</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from '@/api/common'
import { editCustomer } from '@/api/user'
export default {
  name: 'ProfileGuide',
  components: {
  },
  data() {
    return {
      name: '',
      showModal: false,
      imageSrc: '',
      cropper: null
    }
  },
  created() {
    this.name = this.$route.query.name
  },
  beforeDestroy() {
    this.cleanup() // 组件销毁时清理
  },
  methods: {
    handleImg(event) {
      const file = event.target.files[0]
      if (file) {
        // if (file.size > 5 * 1024 * 1024) {
        //   this.$dialog.alert({ message: '图片大小不能超过5MB' })
        //   return
        // }
        // 先清理旧实例
        this.cleanup()
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageSrc = e.target ? e.target.result : ''
          this.showModal = true
          // 等待 DOM 更新后再初始化
          this.$nextTick(() => {
            this.initCropper()
          })
        }
        reader.readAsDataURL(file)
      }
    },
    initCropper() {
      // 确保 Cropper.js 已加载
      if (!window.Cropper) {
        console.error('Cropper.js not loaded yet. Retrying...')
        setTimeout(() => this.initCropper(), 100)
        return
      }

      const image = this.$refs.image
      if (image) {
        // 如果已有 cropper，先销毁
        if (this.cropper) {
          console.log('Destroying existing Cropper instance')
          this.cropper.destroy()
          this.cropper = null
        }

        this.cropper = new window.Cropper(image, {
          aspectRatio: 1,
          viewMode: 1,
          movable: false,
          zoomable: true,
          scalable: true,
          zoomOnTouch: true,
          zoomOnWheel: false,
          autoCropArea: 0.8,
          responsive: false,
          guides: true,
          center: true,
          ready: () => {
            console.log('Cropper is ready')
          }
        })
        console.log('New Cropper initialized:', this.cropper)
      }
    },
    cropImage() {
      if (!this.cropper) {
        console.error('Cropper not initialized')
        this.$store.state.isLoading = false
        return
      }
      this.showModal = false
      this.$store.state.isLoading = true
      const canvas = this.cropper.getCroppedCanvas({
        width: 500,
        height: 500,
        maxWidth: 1000,
        maxHeight: 1000,
        fillColor: '#fff'
      })
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Failed to generate blob')
            this.$store.state.isLoading = false
            this.cleanup()
            return
          }
          const file = new File([blob], 'file.png', { type: 'image/png' })
          this.onUpload(file)
        },
        'image/png',
        0.9
      )
    },
    cancelCrop() {
      this.cleanup()
    },
    cleanup() {
      if (this.cropper) {
        this.cropper.destroy()
        this.cropper = null
        console.log('Cropper destroyed')
      }
      // 清理 Cropper.js 生成的 DOM
      const cropperContainer = document.querySelector('.cropper-container')
      console.log(324324, cropperContainer)
      if (cropperContainer) {
        cropperContainer.remove()
        console.log('Removed cropper-container from DOM')
      }
      // 重置状态
      this.showModal = false
      this.imageSrc = ''
      const fileInput = this.$refs.fileInput
      if (fileInput) {
        fileInput.value = ''
      }
    },
    rotateImage(angle) {
      if (this.cropper) {
        this.cropper.rotate(angle) // 相对旋转
      }
    },
    zoomImage(ratio) {
      if (this.cropper) {
        this.cropper.zoom(ratio)
      }
    },
    onUpload(file) {
      this.$store.state.isLoading = true
      // const file = e.target.files[0]
      upload(file, 'file.png', 10).then(res => {
        const file_name = res.data.file_name
        const param = {}
        param.headimg = file_name
        param.customer_user_id = this.$store.getters.customer_user_id
        editCustomer(param).then(res => {
          this.$store.state.isLoading = false
          this.$router.replace({ name: 'Home' })
        }).catch((res) => {
          this.$store.state.isLoading = false
          this.$dialog.alert({ message: res.msg || res })
        })
        this.cleanup()
      }).catch((res) => {
        this.$store.state.isLoading = false
        this.cleanup()
        this.$dialog.alert({ message: res.msg || res })
      })
    }
  }
}
</script>
    <style lang="less" scoped>
    @import "./profile-guide.less";
    </style>

