<template>
  <div class="container">
    <div class="bg" />
    <div class="content">
      <div class="title">
        确认{{ name }}本人操作
        <div class="remark">请保持正脸在取景框中根据屏幕指示完成识别</div>
      </div>
      <div class="profile">
        <img class="img" src="../../assets/profile.png">
      </div>
      <div class="explain">
        <div class="notice">
          <img class="img" src="../../assets/profile_icon1.png">
          <div>复杂背景</div>
        </div>
        <div class="notice">
          <img class="img" src="../../assets/profile_icon2.png">
          <div>镜片反光</div>
        </div>
        <div class="notice">
          <img class="img" src="../../assets/profile_icon3.png">
          <div>正脸表情</div>
        </div>
        <div class="notice">
          <img class="img" src="../../assets/profile_icon4.png">
          <div>光照均匀适中</div>
        </div>
      </div>
      <div class="astrict">
        <div>· 小于2MB</div>
        <div>· 面部区域像素不低于128px*128px</div>
        <div>· 人像大小占整张照片1/3以上</div>
      </div>
      <div class="btn-face" @click="toFileGuide">
        <img class="img" src="../../assets/profile_camera.png">
        <div>立即设置</div>
        <!-- <input id="upload" type="file" accept="image/*" @change="onUpload"> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      name: ''
    }
  },
  created() {
    this.name = this.$route.query.name
  },
  methods: {
    toFileGuide() {
      this.$router.push({ name: 'ProfileGuide', query: { name: this.name }})
    }
  }
}
</script>

  <style lang="less" scoped>
  @import "./profile.less";
  </style>

